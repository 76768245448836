<script setup>
import { storeToRefs } from 'pinia';
import LinkIos from 'assets/logos/ios.svg?url';
import LinkAndroid from 'assets/logos/android.svg?url';
import Sparkle from 'assets/illustrations/illu_spark_06.svg';
import { useMessageStore } from '~/stores/messages';

// Fetch messages
const messagesStore = useMessageStore();
const { messages: staticKeys } = storeToRefs(messagesStore);

// Markdown
const { $md } = useNuxtApp();
const pageHeadline = staticKeys.value?.hero_app_teaser_headline_web_markdown ? $md.renderInline(staticKeys.value?.hero_app_teaser_headline_web_markdown) : '';
</script>

<template>
    <section class="app-download-invite-header">
        <div class="app-download-invite-header-background" />
        <div class="app-download-invite-header-container">
            <h1 class="app-download-invite-header-headline" v-html="pageHeadline" />
            <p class="app-download-invite-header-copy">{{ staticKeys?.hero_app_teaser_txt_web }}</p>

            <div class="app-download-invite-header-icon">
                <Sparkle />
            </div>

            <div class="app-download-invite-header-btn-container">
                <NuxtLink
                    :to="staticKeys?.account_gettheapp_ios_link"
                    :title="staticKeys?.footer_appteaser_ios_alt_web"
                >
                    <img :src="LinkIos" :alt="staticKeys?.footer_appteaser_ios_alt_web" width="140" height="42">
                </NuxtLink>
                <NuxtLink
                    :to="staticKeys?.account_gettheapp_android_link"
                    :title="staticKeys?.footer_appteaser_android_alt_web"
                >
                    <img
                        :src="LinkAndroid" :alt="staticKeys?.footer_appteaser_android_alt_web" width="140"
                        height="42"
                    >
                </NuxtLink>
            </div>

            <div class="app-download-invite-header-media">
                <div class="app-download-invite-header-img-wrapper">
                    <div class="app-download-invite-header-img-container">
                        <picture>
                            <RespImgSrc
                                tag-type="source"
                                image-src="images/app-download-invite-v2-desktop.png"
                                :srcset="[697, 596, 489]"
                                :dpr="[1,2,3]"
                                :format="['avif', 'webp', 'png']"
                                :width="697"
                                :height="557"
                                media="(min-width: 1024px)"
                                sizes="(min-width: 1450px) 697px, calc(49vw - 12.5px)"
                            />
                            <RespImgSrc
                                tag-type="source"
                                image-src="images/app-download-invite-hero-v2-tablet.png"
                                :srcset="[503, 452, 376]"
                                :dpr="[1,2,3]"
                                :format="['avif', 'webp', 'png']"
                                :width="503"
                                :height="699"
                                media="(min-width: 768px)"
                                sizes="calc(50vw - 8px)"
                            />
                            <RespImgSrc
                                tag-type="source"
                                image-src="images/app-download-invite-hero-v2-mobile.png"
                                :srcset="[448, 310, 390, 215]"
                                :dpr="[1,2,3]"
                                :format="['avif', 'webp', 'png']"
                                :width="448"
                                :height="647"
                                sizes="calc(59.2vw - 6.4px)"
                            />
                            <RespImgSrc
                                tag-type="img"
                                image-src="images/app-download-invite-v2-desktop.png"
                                :srcset="[697]"
                                :dpr="[1,2,3]"
                                sizes="calc(59.2vw - 6.4px)"
                                :format="['png']"
                                :width="697"
                                :height="557"
                                :additional-attrs="{alt:staticKeys?.appteaser_alt_web, fetchpriority:'high'}"
                            />
                        </picture>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style lang="scss">
.app-download-invite-header {
    @include fullbleed;

    position: relative;
    margin-block-start: calc(px2rem(-73) - 36px);
    background: $app-download-invite-header-wrapper-bg;
    border-top: 1px solid $app-download-invite-header-wrapper-border;

    @include bp(medium1) {
        background: transparent;
        border-top: 0 none;
        margin-block-start: px2rem(-40);
    }

    @include bp(large1) {
        margin-block-start: px2rem(-60);
    }
}

.app-download-invite-header-background {
    overflow: hidden;
    position: absolute;
    z-index: z('stage-hero-gradient');
    inset: 0;

    @include bp(large1) {
        background: $app-download-invite-header-background;
    }

    &::before {
        content: '';
        position: absolute;
        right: 0;
        bottom: 10vh;
        max-width: 1000px;
        max-height: 1000px;
        background: radial-gradient(56.32% 74.23% at 50% 50%, $app-download-invite-gradient1 0%, $app-download-invite-gradient2 100%);
        filter: blur(50px);
        height: 50vh;
        width: 100vw;

        @include bp(medium1) {
            bottom: auto;
            //filter: blur(100px);
            width: 80vw;
            height: 80vw;
        }

        @include bp(1216px) {
            left: calc(50vw - 216px);
        }
    }
}

.app-download-invite-header-container {
    @include content-grid;

    align-items: flex-start;
    position: relative;
    z-index: z('app-download-invite-container');
    grid-template-rows: auto auto 1fr auto;

    @include bp(medium1) {
        grid-template-rows: auto auto 1fr;
    }
}

.app-download-invite-header-headline {
    @include h1-mixed;

    text-align: left;
    margin: 90px 0 44px;
    grid-column: 1/ span 3;
    grid-row: 1;

    @include bp(small3) {
        grid-column: 1/ span 2;
    }

    @include bp(medium1) {
        margin: 40px 0 16px;
        grid-column: 1/ span 4;
    }

    @include bp(large1) {
        margin: 100px 0 0;
        grid-column: 1/ span 5;
    }

    @include bp(large2) {
        grid-column: 1/ span 4;
    }
}

.app-download-invite-header-copy {
    @include body1-medium;

    margin: 14px 0 40px;
    grid-column: 1/ span 5;
    grid-row: 4;

    @include bp(small3) {
        grid-column: 1/ span 4;
    }

    @include bp(medium1) {
        grid-column: 1/ span 4;
        grid-row: 2;
        margin: 0 0 24px;
    }

    @include bp(large1) {
        grid-column: 1/ span 5;
        margin: 0 0 10px;
    }

    @include bp(large2) {
        grid-column: 1/ span 4;
    }
}

.app-download-invite-header-icon {
    grid-row: 3;
    grid-column: 2/ span 1;

    @include bp(medium1) {
        display: none;
    }

    svg {
        width: 32px;
    }
}

.app-download-invite-header-btn-container {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    margin: 0 0 58px;
    grid-column: 1/ span 2;
    grid-row: 2;

    @include bp(medium1) {
        margin: 0 0 44px;
        grid-column: 1/ span 4;
        grid-row: 3;
    }

    @include bp(large1) {
        margin: 0 0 44px;
        grid-column: 1/ span 5;
    }

    @include bp(large2) {
        grid-column: 1/ span 4;
    }

    a {
        display: block;
        line-height: 0;
        margin: 0 16px 16px 0;

        @include bp(medium1) {
            max-width: 140px;
        }

        img {
            width: 120px;
            height: auto;

            @include bp(medium1) {
                width: 140px;
            }
        }
    }
}

.app-download-invite-header-media {
    grid-column: 3 / span 3;
    grid-row: 1 / span 3;
    place-content: baseline flex-end;
    display: flex;
    height: 100%;

    @include bp(medium1) {
        grid-column: 5 / span 4;
    }

    @include bp(large1) {
        grid-column: 6 / span 7;
    }
}

.app-download-invite-header-img-wrapper {
    margin: auto -4vw 0;
    width: calc(100% + 4vw);
    display: flex;
    justify-content: flex-end;
    padding-top: 144px;

    @include bp(medium1) {
        margin: auto -4vw -150px;
        padding-top: 80px;
    }

    @include bp(large1) {
        margin: auto 0 -60px;
        padding-top: 30px;
    }
}

.app-download-invite-header-img-container {
    aspect-ratio: 9 / 13;
    position: relative;
    width: 100%;

    @include bp(medium1) {
        aspect-ratio: 18 / 25;
    }

    @include bp(large1) {
        aspect-ratio: 5 / 4;
    }

    img {
        display: block;
        max-width: 100%;
        width: 100%;
        height: auto;
        position: absolute;
        bottom: 0;
        right: 0;
    }
}
</style>
